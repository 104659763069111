import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import moment from 'moment';

import { useFormDispatch, useFormState } from '../../context/FormContext';
import { useAuth } from '../../context/AuthContext';
import useStyles from '../../styles/evals/auditFieldStyles';
import {
  STATUS,
  FORM_TYPE,
  NEXT_EVAL_DATE_QUESTION_TEXT
} from '../../helpers/constants';
import { useQuery } from '@apollo/react-hooks';
import {
  toCamel,
  updateResponse,
  formIsReadOnly,
  idMatch
} from '../../helpers/helpers';
import { DatePickerCalendar } from '../common';
import { FETCH_NEXT_EVAL_DATE } from '../../graphql/nextEval';

/* Primary form fields containing employee information */
const AuditField = ({
  questionText,
  questionType,
  questionId,
  sectionId,
  newResponses,
  setNewResponses
}) => {
  const { dispatch } = useFormDispatch();
  const {
    state: {
      formResponses,
      formConfig,
      auditData: {
        evaluationFor,
        evaluationForEmail,
        evaluationForEmployeeId,
        lastEvalDate,
        jobTitle,
        evaluationForJobNumber,
        evaluationForDistrictNumber,
        evaluationForHireDate,
        assignedToEmail,
        assignedToEmployeeId,
        nextEvalDate,
        udCompetency
      }
    }
  } = useFormState();

  const { userData } = useAuth();
  const classes = useStyles();

  // Conditionals
  const formIsClosed = formConfig.status === STATUS.ARCHIVED.NAME;
  const formIsPublished = formConfig.status === STATUS.PUBLISHED.NAME;
  const formIsLocked =
    formIsClosed ||
    formIsReadOnly(
      userData.email,
      assignedToEmail,
      userData.employeeId,
      assignedToEmployeeId,
      formConfig.view
    ) ||
    formIsPublished;
  const formTypeIsIntern = formConfig.formTypeId === FORM_TYPE.INTERN.ID;
  const userIsEvaluated =
    idMatch(userData.email, evaluationForEmail) ||
    idMatch(userData.employeeId, evaluationForEmployeeId);
  const userIsAdmin = userData.role === 'Admin';

  const updateAuditField = fieldValue => {
    dispatch({
      type: 'setResponseValue',
      payload: {
        sectionId,
        questionId,
        response: fieldValue,
        field: 'employeeResponse'
      }
    });
    updateResponse(
      sectionId,
      questionId,
      'employeeResponse',
      fieldValue,
      newResponses,
      setNewResponses,
      formResponses
    );
  };

  const { data: nextEvalData } = useQuery(FETCH_NEXT_EVAL_DATE, {
    variables: { userName: evaluationForEmail },
    skip: !evaluationFor,
    fetchPolicy: 'no-cache'
  });

  // get date of this user's next evaluation
  const getNextEvalDate = ({ fetchNextEvalDateForUser }) => {
    if (fetchNextEvalDateForUser) {
      const date = fetchNextEvalDateForUser;
      return moment(date).format('L');
    }
    return null;
  };

  const defaultValues = {
    [NEXT_EVAL_DATE_QUESTION_TEXT]: null
  };

  const getInitialValue = fieldText => {
    // get response for this field
    const auditResponse = formResponses[sectionId][questionId].employeeResponse;
    if (auditResponse) return auditResponse;

    const auditFields = {
      'Evaluation For': evaluationFor,
      "Intern's Name": evaluationFor,
      'Last Evaluation Date': lastEvalDate,
      'Job Title': jobTitle,
      'Region': evaluationForDistrictNumber, // prettier-ignore
      'Job Number': evaluationForJobNumber,
      'Hire Date': evaluationForHireDate,
      [NEXT_EVAL_DATE_QUESTION_TEXT]: nextEvalDate
        ? nextEvalDate
        : nextEvalData
        ? getNextEvalDate(nextEvalData)
        : null
    };

    if (auditFields[fieldText]) {
      updateAuditField(auditFields[fieldText]);
    }

    return auditFields[fieldText];
  };

  const defaultValue = defaultValues[questionText];
  const initialValue = getInitialValue(questionText);

  const [value, setValue] = useState(initialValue);

  // Use effect is necessary because the TextField component does not re-render when props change
  useEffect(() => {
    if (questionType === 'TextField' || isNextEvalDate()) {
      setValue(initialValue);
    }
  }, [initialValue, questionType]);

  const handleAuditFieldChange = event => {
    event.persist();
    const response = event.target.value;
    setValue(response);
    updateAuditField(response);
  };

  const handleAuditDateFieldChange = value => {
    if (isNextEvalDate() && userIsAdmin) {
      setValue(value);
      const dateString = value ? value.format('YYYY-MM-DD') : null;
      updateAuditField(dateString);
    }
  };

  const fieldDisabled = () => {
    if (isNextEvalDate() && userIsAdmin) {
      return false;
    }
    if (formIsLocked) return true;

    const disabledFields = [
      'Evaluation For',
      "Intern's Name",
      'Job Title',
      'Region',
      'Job Number',
      'Hire Date'
    ];

    if (disabledFields.includes(questionText)) return true;

    if (formTypeIsIntern) {
      // intern cannot alter attendance or supervisor section
      if (userIsEvaluated) return sectionId === 3 || sectionId === 9;
      // reviewer cannot alter intern section
      return sectionId === 10;
    }

    return false;
  };

  const isNextEvalDate = () => {
    return questionText === NEXT_EVAL_DATE_QUESTION_TEXT;
  };

  const chooseTextFieldClass = () => {
    if (formTypeIsIntern) {
      if (sectionId === 0) {
        return classes.employeeInfoField;
      }

      if (sectionId === 3) {
        return classes.internAttendanceFields;
      }

      return classes.additionalFields;
    }

    return classes.employeeInfoField;
  };
  if (questionType === 'DateField') {
    return (
      <div className={classes.datePicker}>
        <DatePickerCalendar
          labelName={questionText}
          id={`auditDateField-${questionId}`}
          disabled={fieldDisabled}
          selectedValue={value}
          onChange={handleAuditDateFieldChange}
          setDisableFuture={false}
          setDisablePast={false}
          isClearable={true}
          clearLabel={defaultValue ? 'RESET' : 'CLEAR'}
          inputVariant="outlined"
        />
      </div>
    );
  }
  if (questionType === 'TextField') {
    return (
      <TextField
        margin="dense"
        variant="outlined"
        label={questionText}
        value={value || ''}
        className={chooseTextFieldClass()}
        onChange={handleAuditFieldChange}
        data-cy={toCamel(questionText)}
        disabled={fieldDisabled()}
      />
    );
  }
  return null;
};

AuditField.propTypes = {
  questionText: PropTypes.string.isRequired,
  questionType: PropTypes.string,
  questionId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  newResponses: PropTypes.arrayOf(PropTypes.any).isRequired,
  setNewResponses: PropTypes.func.isRequired
};

export default AuditField;
