import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../context/AuthContext';

import PageError from './PageError';

function withRefreshLogin(Component) {
  return function WrappedComponent(props) {
    const { refreshLogin } = useAuth();
    return <Component {...props} refreshLogin={refreshLogin} />;
  };
}

class ErrorBoundary extends Component {
  state = { hasError: false };

  ERROR_MESSAGE = {
    TITLE: 'Oh no! Something went wrong.',
    SUBTITLE:
      'Please refresh the page. If the error persists reach out to your system administrator.'
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error) {
    if (error.message.includes('[TOKENERROR]')) {
      try {
        await this.props.refreshLogin();
        this.state.hasError = false;
        window.location.reload();
      } catch (refreshError) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <PageError
          title={this.ERROR_MESSAGE.TITLE}
          subtitle={this.ERROR_MESSAGE.SUBTITLE}
          notFoundPage={false}
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default withRefreshLogin(ErrorBoundary);
