import gql from 'graphql-tag';

export const LOGIN_USER = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      token
      user
    }
  }
`;
export const TOKEN_REFRESH = gql`
  mutation TokenRefresh {
    tokenRefresh {
      user
    }
  }
`;

export const CREATE_PPE = gql`
  mutation CreatePPE(
    $assignedTo: String!
    $assignedToEmail: String!
    $assignedToEmployeeId: Int
    $assignedOn: DateTime
    $evaluationFor: String!
    $evaluationForEmail: String!
    $evaluationForEmployeeId: Int
    $evaluationForJobNumber: String
    $evaluationForRole: String!
    $ppeTypeId: Int!
    $status: Int!
    $sent: Boolean!
    $jobTitle: String
    $udCompetency: String
  ) {
    createPPE(
      assignedTo: $assignedTo
      assignedToEmail: $assignedToEmail
      assignedToEmployeeId: $assignedToEmployeeId
      assignedOn: $assignedOn
      evaluationFor: $evaluationFor
      evaluationForEmail: $evaluationForEmail
      evaluationForEmployeeId: $evaluationForEmployeeId
      evaluationForJobNumber: $evaluationForJobNumber
      evaluationForRole: $evaluationForRole
      ppeTypeId: $ppeTypeId
      status: $status
      sent: $sent
      jobTitle: $jobTitle
      udCompetency: $udCompetency
    ) {
      id
      createdOn
    }
  }
`;

export const SUBMIT_EVAL = gql`
  mutation SubmitEval(
    $ppeId: Int!
    $evaluationFor: String!
    $evaluationForEmail: String!
    $assignedTo: String!
    $assignedToEmail: String!
    $assignedToEmployeeId: Int
    $assignedOn: DateTime
    $status: Int!
  ) {
    submitEval(
      ppeId: $ppeId
      evaluationFor: $evaluationFor
      evaluationForEmail: $evaluationForEmail
      assignedTo: $assignedTo
      assignedToEmail: $assignedToEmail
      assignedToEmployeeId: $assignedToEmployeeId
      assignedOn: $assignedOn
      status: $status
    ) {
      id
    }
  }
`;

export const SAVE_EVAL = gql`
  mutation SaveEval($ppeId: Int!, $jobTitle: String, $udCompetency: String) {
    saveEval(ppeId: $ppeId, jobTitle: $jobTitle, udCompetency: $udCompetency) {
      id
      jobTitle
      udCompetency
    }
  }
`;

export const PUBLISH_EVAL = gql`
  mutation PublishEval(
    $ppeId: Int!
    $evaluationFor: String!
    $evaluationForEmail: String!
  ) {
    publishEval(
      ppeId: $ppeId
      evaluationFor: $evaluationFor
      evaluationForEmail: $evaluationForEmail
    ) {
      id
    }
  }
`;

export const UNPUBLISH_EVAL = gql`
  mutation UnpublishEval($ppeId: Int!) {
    unpublishEval(ppeId: $ppeId) {
      id
    }
  }
`;

export const ARCHIVE_EVAL = gql`
  mutation ArchiveEval(
    $ppeId: Int!
    $evaluationFor: String!
    $evaluationForEmail: String!
  ) {
    archiveEval(
      ppeId: $ppeId
      evaluationFor: $evaluationFor
      evaluationForEmail: $evaluationForEmail
    ) {
      id
      modifiedOn
      createdOn
    }
  }
`;

export const SOFT_DELETE_PPE = gql`
  mutation SoftDeletePPE($ppeId: Int!) {
    softDeletePPE(ppeId: $ppeId) {
      id
    }
  }
`;

export const UPSERT_RESPONSES = gql`
  mutation UpsertResponses($input: UpsertResponsesInput!) {
    upsertResponses(input: $input) {
      id
    }
  }
`;

export const UPSERT_COMMENTS = gql`
  mutation UpsertComments($input: UpsertCommentsInput!) {
    upsertComments(input: $input) {
      id
      createdBy
      createdByEmail
      createdByEmployeeId
      createdOn
      comment
      sectionId
    }
  }
`;

export const DELETE_COMMENTS = gql`
  mutation DeleteComments($ids: [Int!]) {
    deleteComments(ids: $ids) {
      comment
    }
  }
`;
export const ARCHIVE_DATA = gql`
  mutation ArchiveData($input: AvroInput) {
    archiveData(input: $input) {
      id
    }
  }
`;
