import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Edit, Visibility, LocalLibrary as View } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';

import AutoScroll from '../common/AutoScroll';
import { useFormDispatch } from '../../context/FormContext';
import { useDashboardSearchState } from '../../context/DashboardSearchContext';
import { useAuth } from '../../context/AuthContext';
import { STATUS } from '../../helpers/constants';
import {
  regularSort,
  convertToUpperCase,
  formIsReadOnly,
  idMatch
} from '../../helpers/helpers';
import { userIsAdmin } from '../../helpers/roleHelpers';
import {
  getMuiTheme,
  getEmptyMuiTheme,
  inactiveRowHighlight,
  inactiveCellHighlight
} from '../../styles/common/reviewTableStyles';

/* Component for Dashboard table containing evaluations */
const ReviewTable = ({ data: evalData }) => {
  const { dispatch } = useFormDispatch();
  const {
    state: { view }
  } = useDashboardSearchState();

  const { userData } = useAuth();
  const history = useHistory();

  // Conditionals
  const userIsInactive = index => evalData[index].inactive;

  // Check if user has permissions to view an evaluation that's been published
  const canViewPublishedReview = row => {
    const [status, , , , , , , , evaluationForEmail] = row;
    const assignedToEmail = row[9];
    const assignedToEmployeeId = row[13];
    const evaluationForEmployeeId = row[14];
    if (status !== STATUS.PUBLISHED.NAME) return false;

    // only admins, users assigned or being evaluated can view published evaluations
    return (
      idMatch(evaluationForEmail, userData.email) ||
      idMatch(evaluationForEmployeeId, userData.employeeId) ||
      idMatch(assignedToEmail, userData.email) ||
      idMatch(assignedToEmployeeId, userData.employeeId) ||
      userIsAdmin(userData.role)
    );
  };

  // Check if user can open a read-only closed evaluation
  const canViewClosedReview = row => {
    const [status] = row;
    const assignedToEmail = row[9];
    const assignedToEmployeeId = row[13];

    // on role views, evaluations can be opened in read-only mode
    const readOnly = formIsReadOnly(
      userData.email,
      assignedToEmail,
      userData.employeeId,
      assignedToEmployeeId,
      view
    );
    if (readOnly) return true;

    return status === STATUS.ARCHIVED.NAME;
  };

  // Check if user can open & edit an evaluation
  const canEditOpenReview = row => {
    const [status, , , , , , , , evaluationForEmail, assignedToEmail] = row;
    const assignedToEmployeeId = row[13];
    const evaluationForEmployeeId = row[14];

    if (status !== STATUS.OPEN.NAME) return false;

    // assigned users can edit
    if (
      idMatch(assignedToEmail, userData.email) ||
      idMatch(assignedToEmployeeId, userData.employeeId)
    )
      return true;

    // admins can edit evaluations for others
    return (
      userIsAdmin(userData.role) &&
      !idMatch(evaluationForEmail, userData.email) &&
      !idMatch(evaluationForEmployeeId, userData.employeeId)
    );
  };

  // set data for the opened evaluation into state & navigate to the Evaluation
  const openPPE = ppeData => {
    const [
      status,
      formType,
      evaluationFor,
      assignedTo,
      ,
      ,
      ppeId,
      formTypeId,
      evaluationForEmail,
      assignedToEmail,
      createdBy,
      createdByEmail,
      createdByEmployeeId,
      assignedToEmployeeId,
      evaluationForEmployeeId,
      sent,
      jobTitle,
      udCompetency
    ] = ppeData;

    dispatch({
      type: 'setAuditDataValue',
      payload: {
        evaluationFor,
        evaluationForEmail,
        evaluationForEmployeeId,
        assignedTo,
        assignedToEmail,
        assignedToEmployeeId,
        jobTitle,
        udCompetency
      }
    });
    dispatch({
      type: 'setFormConfig',
      payload: {
        ppeId,
        formTypeId,
        status,
        formType,
        createdBy,
        createdByEmail,
        createdByEmployeeId,
        sent,
        view
      }
    });

    history.push('/evaluation');
  };

  // Table structure
  const columns = [
    {
      name: 'ppeStatus',
      label: 'Status'
    },
    {
      name: 'ppeTypeName',
      label: 'Form'
    },
    {
      name: 'evaluationFor',
      label: 'Evaluation For'
    },
    {
      name: 'assignedTo',
      label: 'Assigned To',
      options: {
        setCellProps: (cellValue, rowIndex) => {
          return userIsInactive(rowIndex) ? inactiveCellHighlight() : null;
        }
      }
    },
    {
      name: 'createdOn',
      label: 'Date Created'
    },
    {
      name: 'daysAssigned',
      label: 'Days Assigned'
    },
    {
      name: 'id',
      options: { sortDirection: 'desc', display: false }
    },
    {
      name: 'ppeTypeId',
      options: { display: false }
    },
    {
      name: 'evaluationForEmail',
      options: { display: false }
    },
    {
      name: 'assignedToEmail',
      options: { display: false }
    },
    {
      name: 'createdBy',
      options: { display: false }
    },
    {
      name: 'createdByEmail',
      options: { display: false }
    },
    {
      name: 'createdByEmployeeId',
      options: { display: false }
    },
    {
      name: 'assignedToEmployeeId',
      options: { display: false }
    },
    {
      name: 'evaluationForEmployeeId',
      options: { display: false }
    },
    {
      name: 'sent',
      options: { display: false }
    },
    {
      name: 'jobTitle',
      options: { display: false }
    },
    {
      name: 'udCompetency',
      options: { display: false }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;
          let icon;
          if (canViewPublishedReview(rowData)) {
            icon = (
              <section>
                <Visibility
                  style={{ cursor: 'pointer' }}
                  onClick={() => openPPE(rowData)}
                  data-cy="publishedBtn"
                />
              </section>
            );
          } else if (canViewClosedReview(rowData)) {
            const idIndex = columns.findIndex(c => c.name === 'id');
            icon = (
              <section>
                <View
                  style={{ cursor: 'pointer' }}
                  onClick={() => openPPE(rowData)}
                  data-cy="viewBtn"
                  data-id={`ppeID${rowData[idIndex]}`}
                />
              </section>
            );
          } else if (canEditOpenReview(rowData)) {
            icon = (
              <section>
                <Edit
                  style={{ cursor: 'pointer' }}
                  onClick={() => openPPE(rowData)}
                  data-cy="editBtn"
                />
              </section>
            );
          }
          return icon;
        }
      }
    }
  ];

  /* Custom Sort Function */
  const sortColumns = (data, colIndex, order) => {
    // the undisplayed id column allows for a default sort
    // separate from the user-sortable columns
    const idIndex = columns.findIndex(c => c.name === 'id');

    return data.sort((a, b) => {
      if (colIndex === idIndex) {
        return defaultSort(a.data, b.data, order);
      }

      // regular sorting for other columns
      const itemA = convertToUpperCase(a.data[colIndex]);
      const itemB = convertToUpperCase(b.data[colIndex]);

      return regularSort(itemA, itemB, order);
    });
  };

  const defaultSort = (dataA, dataB, order) => {
    const assignedToIndex = columns.findIndex(c => c.name === 'assignedTo');
    const daysAssignedIndex = columns.findIndex(c => c.name === 'daysAssigned');

    const assignedToA = dataA[assignedToIndex].toUpperCase();
    const assignedToB = dataB[assignedToIndex].toUpperCase();
    const daysAssignedA = dataA[daysAssignedIndex];
    const daysAssignedB = dataB[daysAssignedIndex];

    // current logged in user has evaluations sorted by daysAssigned
    if (
      assignedToA === userData.name.toUpperCase() &&
      assignedToB === userData.name.toUpperCase()
    ) {
      if (daysAssignedA === daysAssignedB) return 0;
      return daysAssignedA > daysAssignedB ? -1 : 1;
    }

    // current logged in user should always be listed before other users
    if (assignedToA === userData.name.toUpperCase()) {
      return -1;
    }

    if (assignedToB === userData.name.toUpperCase()) {
      return 1;
    }

    // other users sort by days assigned reversed
    return regularSort(daysAssignedB, daysAssignedA, order);
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRows: 'none',
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20],
    customSort: sortColumns,
    setRowProps: (row, dataIndex) => {
      return userIsInactive(dataIndex) ? inactiveRowHighlight() : null;
    }
  };

  return (
    <MuiThemeProvider
      theme={evalData.length ? getMuiTheme() : getEmptyMuiTheme()}
    >
      <AutoScroll type="scrollToTop" />
      <MUIDataTable data={evalData} columns={columns} options={options} />
      <AutoScroll type="scrollToBottom" />
    </MuiThemeProvider>
  );
};

ReviewTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      ppeId: PropTypes.number,
      status: PropTypes.string,
      evaluationFor: PropTypes.string,
      assignedTo: PropTypes.string,
      createdOn: PropTypes.string,
      daysAssigned: PropTypes.number,
      jobTitle: PropTypes.string
    })
  ).isRequired
};

export default ReviewTable;
